import React from 'react'
import animateScrollTo from 'animated-scroll-to';
import AnimatedLink from './animatedLink';
import Checklist from './checklist';
import { bind } from 'size-sensor';
// @ts-ignore
import ArrowLeftDark from '../../svgs/arrow-left-dark.svg'
// @ts-ignore
import DownloadButton from '../../svgs/download-button.svg'
import {isIE} from 'react-device-detect';


interface props {
  data: any
  mobile: boolean
  pageType: string
}

interface state {
  sideNavOffset: number
  bottomTriggerHeight: number
  checklistHeight: number
  contentHeight: number
  menuItems: any
  sideNavActive: boolean
  checklistActive: boolean
  contentActive: boolean
}

export default class Sidenav extends React.Component<props, state> {
  scrollEventListener: Function;
  sideNavEl: HTMLElement

  constructor(props) {
    super(props);
    this.state = {
      sideNavOffset: 0,
      bottomTriggerHeight: 0,
      menuItems: [],
      sideNavActive: false,
      checklistActive: false,
      contentActive: true,
      checklistHeight: 0,
      contentHeight: 0
    };
  }
  componentDidMount = () => {
    if (this.props.mobile) {
      this.mobileStartup();
    } else {
      this.desktopStartup();
    }
  }

  componentWillUnmount = () => {
    // @ts-ignore
    window.removeEventListener('scroll', this.scrollEventListener);
    // @ts-ignore
    this.unbind();
  }

  desktopStartup = async () => {
    await this.checkPageContents()
    await this.setHeight()
    this.setOffsets();
    this.scrollEvent();
    this.fadeIn()
    this.setResizeEvent()
  }


  mobileStartup = async () => {
    await this.checkPageContents()
    await this.setHeight()
    this.fadeIn()
    this.setResizeEvent()
  }

  setHeight = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        
        let contentHeight = document.querySelector(`.sequence-sidenav__content`).scrollHeight + 15;
        let checklistHeight = document.querySelector(`.sequence-sidenav__checklist`).scrollHeight + 15;
        // @ts-ignore
        document.querySelector(`.sequence-sidenav__checklist`).style.height = "100%";
        this.sideNavEl.style.height = contentHeight + "px";
        
        this.setState({
          checklistHeight: checklistHeight,
          contentHeight: contentHeight
        }, () => resolve())
      }, 200);
    });
  }

  setResizeEvent = () => {
    // @ts-ignore
    this.unbind = bind(document.getElementById('sequence-content'), () => {
      this.checkPageContents();
      this.setBottomTriggerHeight();
      this.updateProgressBar();
    });
  }

  updateProgressBar = () => {
    let progressBar = document.getElementsByTagName('progress')[0];
    if (progressBar !== undefined) {
      let contentContainer = document.getElementById('sequence-content');

      let containerHeight = contentContainer.offsetHeight - 900;
      // @ts-ignore
      progressBar.setAttribute('max', containerHeight);
    }
  }


  fadeIn = () => {
    this.setState({sideNavActive: true})
  }

  scrollEvent = () => {
    window.addEventListener('scroll', this.scrollEventListener = (e) => {
      this.stickyNavCheck(window.pageYOffset);
      
      window.requestAnimationFrame(() => {
        this.menuItemHighlightCheck(window.pageYOffset);
      })
    });  
  }
  
  setOffsets = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const sideNavOffset = (this.sideNavEl.getBoundingClientRect().top + scrollTop) - 130;
    const sideNavHeight = this.sideNavEl.offsetHeight;
    const sideNavHeightWithMargin = sideNavHeight + 160;
    const footerTopOffset = document.querySelector('.footer').getBoundingClientRect().top + scrollTop;
    const bottomTriggerHeight = footerTopOffset - sideNavHeightWithMargin;

    this.setState({
      sideNavOffset: sideNavOffset,
      bottomTriggerHeight: bottomTriggerHeight
    })
  }
  
  setBottomTriggerHeight = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const sideNavHeight = this.sideNavEl.offsetHeight;
    const sideNavHeightWithMargin = sideNavHeight + 160;
    const footerTopOffset = document.querySelector('.footer').getBoundingClientRect().top + scrollTop;
    const bottomTriggerHeight = footerTopOffset - sideNavHeightWithMargin;

    this.setState({
      bottomTriggerHeight: bottomTriggerHeight
    })
  }

  stickyNavCheck = (scroll) => {
    let hideNavOffset = document.body.classList.contains('hide-nav') ? 95 : 0;

    if (scroll > this.state.sideNavOffset) {
      this.sideNavEl.classList.add('sticky')

      if (scroll > this.state.bottomTriggerHeight + hideNavOffset) {
        this.sideNavEl.classList.add('bottom')
      } else {
        this.sideNavEl.classList.remove('bottom')
      }
    } else {
      this.sideNavEl.classList.remove('sticky')
    }
  }

  menuItemHighlightCheck = (scroll) => {
    let links = document.querySelectorAll('.sequence-sidenav__link');
    let activeItemId = '';

    for (let button = 0; button < links.length; button++) {
      links[button].classList.remove("active");
    }

    let filteredMenuItems = this.state.menuItems.filter((item) => {
      return item.offset > scroll
    })

    if (filteredMenuItems.length == 0) {
      filteredMenuItems
      activeItemId = this.state.menuItems[this.state.menuItems.length - 1].id + '-item';
    } else {
      activeItemId = filteredMenuItems[0].id + '-item';
    }

    if (scroll < 100) return

    document.getElementById(activeItemId).classList.add('active');
    
  }

  checkPageContents = () => {
    return new Promise((resolve) => {
      let menuItems = [];

      switch (this.props.pageType) {
        case 'stage':
            const {
              successCriteria,
              stageCurriculumDescription,
              contentSections
            } = this.props.data.stageData;

            menuItems.push({item:'Top of stage', id:'top', offset: 0});

            // if (successCriteria !== '') menuItems.push({item:'Outcomes', id: 'outcomes', offset: this.getOffset('outcomes')})
            if (stageCurriculumDescription !== null) menuItems.push({item:'Content descriptions', id: 'curriculum', offset: this.getOffset('curriculum')})
            if (contentSections.length > 0) {
              for (let i = 0; i < contentSections.length; i++) {
                const section = contentSections[i];
                let sectionId = `stage-section-${i}`;
                menuItems.push({item:section.sectionTitle, id: sectionId, offset: this.getOffset(sectionId)})
              }
            }
            if (document.getElementById('links') !== null) menuItems.push({item:'Sequence links', id: 'links', offset: this.getOffset('links')})
            if (document.getElementById('stage-links') !== null) menuItems.push({item:'Other stages', id: 'stage-links', offset: this.getOffset('stage-links')})
          break;

        case 'sequence':
            const {
              whatIsThisSequenceAbout,
              overview,
              victorianCurriculumConnection,
              engagingParentsAndCarers,
              teachingStrategies,
              priorKnowledge,
              vocabulary,
              assessment
            } = this.props.data.pageData;

            menuItems.push({item:'Top of sequence', id:'top', offset: 0});
            if (whatIsThisSequenceAbout.length > 0) menuItems.push({item:'What is this sequence about?', id: 'whatIsThisSequenceAbout', offset: this.getOffset('whatIsThisSequenceAbout')})
            if (this.props.data.pageData.stages.length > 0) menuItems.push({item:'Overview of stages', id: 'overview',  offset: this.getOffset('overview')})
            if (priorKnowledge !== '') menuItems.push({item:'Prior knowledge', id: 'priorKnowledge',  offset: this.getOffset('priorKnowledge')})
            if (teachingStrategies !== '') menuItems.push({item:'Teaching strategies', id: 'teachingStrategies',  offset: this.getOffset('teachingStrategies')})
            if (vocabulary !== '') menuItems.push({item:'Vocabulary', id: 'vocabulary',  offset: this.getOffset('vocabulary')})
            if (assessment !== '') menuItems.push({item:'Assessment', id: 'assessment',  offset: this.getOffset('assessment')})
            if (victorianCurriculumConnection !== null) menuItems.push({item:'Victorian Curriculum connections', id: 'curriculum',  offset: this.getOffset('curriculum')})
            // if (engagingParentsAndCarers !== '') menuItems.push({item:'Engaging Parents and Carers', id: 'engagingParentsAndCarers',  offset: this.getOffset('engagingParentsAndCarers')})
          break;
          
        case 'stageindex':
            let stages = this.props.data.pageData.stages;

            for (let i = 0; i < stages.length; i++) {
              const stage = stages[i];
              menuItems.push({item: stage.stageTitle, id:`stage-card-${i}`, offset: this.getOffset(`stage-card-${i}`)});
            }
          break;
      }

      this.setState({menuItems: menuItems}, () => resolve())
    })
  }

  getOffset = (id) => {
    const idEl = document.getElementById(id);
    let elOffset = 0;
    
    if (idEl !== null) {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      elOffset = idEl.getBoundingClientRect().top + scrollTop;
    }

    return elOffset
  }

  scrollTo = (offset) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // When top nav hides while scrolling down, sets the correct offset
    let marginOffset = scrollTop < offset ? 28 : 115;

    // IE has issues with the scroll here so just add max margin
    if (isIE) marginOffset = 115;

    animateScrollTo(offset - marginOffset, {speed: 800})
  }


  downloadClick = () => {
    this.setState({
      checklistActive: true,
      contentActive: false
    })
    if (this.props.mobile) {
      this.sideNavEl.style.height = "100%";
    } else {
      this.sideNavEl.style.height = this.state.checklistHeight + "px";
    }
    // @ts-ignore
    document.querySelector(`.sequence-sidenav__checklist`).style.height = "";

    setTimeout(() => this.setBottomTriggerHeight(), 400);
  }

  closeChecklist = () => {
    this.setState({
      checklistActive: false,
      contentActive: true
    })

    this.sideNavEl.style.height = this.state.contentHeight + "px";
    // @ts-ignore
    document.querySelector(`.sequence-sidenav__checklist`).style.height = "100%";

    setTimeout(() => this.setBottomTriggerHeight(), 400);
  }

  getBackButton = () => {
    let data = this.props.data;

    switch (this.props.pageType) {
      case 'stage':
        return (
          <AnimatedLink to={`/${data.pageData.slug}/stages`} className={`sequence-sidenav__back-button`}>
            <ArrowLeftDark/> Back to stages
          </AnimatedLink>
        )
      case 'sequence':
        return (
          <AnimatedLink to={`/learning-areas`} className={`sequence-sidenav__back-button`}>
            <ArrowLeftDark/> Back to Learning areas
          </AnimatedLink>
        )
        
      case 'stageindex':
        return (
          <AnimatedLink to={`/${data.pageData.slug}`} className={`sequence-sidenav__back-button`}>
            <ArrowLeftDark/> Back to overview
          </AnimatedLink>
        )
    }
  }

  render () {
    let data = this.props.data;

    return (
      <div 
        className={`sequence-sidenav ${this.state.sideNavActive ? 'active' : ''}`}
        ref={ref => this.sideNavEl = ref}
      >
        <Checklist 
          data={this.props.data} 
          mobile={this.props.mobile}
          active={this.state.checklistActive}
          closeChecklist={this.closeChecklist}
        />
        <div className={`sequence-sidenav__content ${this.state.contentActive ? 'active' : ''}`}>
          <p className="sequence-sidenav__title">Go to section</p>
          {this.getBackButton()}
          <div className="sequence-sidenav__links">
            {this.state.menuItems.map((menuItem, index) => {
              return (
                <button 
                  key={`menuitem-${index}`}
                  className={`sequence-sidenav__link`}
                  onClick={() => this.scrollTo(menuItem.offset)}
                  id={`${menuItem.id}-item`}
                >
                  > {menuItem.item}
                </button>
              )
            })}
          </div>
          <button className="sequence-sidenav__download-button" onClick={this.downloadClick}>
            <DownloadButton/> Download and customise
          </button>
        </div>
      </div>
    )
  }
}