import React from 'react';
import Checkbox from '@material/react-checkbox';
import DownloadBox from './downloadBox';

// @ts-ignore
import CloseButton from '../../svgs/close-grey.svg';

interface props {
  data: any;
  active: boolean;
  mobile: boolean;
  closeChecklist: any;
}

interface state {
  checklistActive: boolean;
  menuItems: any[];
  stageItems: any[];
  checkedItems: any[];
}

export default class Checklist extends React.Component<props, state> {
  constructor(props) {
    super(props);
    this.state = {
      checklistActive: false,
      menuItems: [],
      stageItems: [],
      checkedItems: [],
    };
  }

  componentDidMount = () => {
    this.checkPageContents();
  };

  componentWillUnmount = () => {};

  setHeight = () => {
    let sideNavEl = document.querySelector(`.sequence-sidenav`);
    let checklistHeight =
      document.querySelector(`.sequence-sidenav__checklist`).scrollHeight + 14;
    // @ts-ignore
    sideNavEl.style.height = checklistHeight + 'px';
  };

  checkPageContents = () => {
    let menuItems = [];
    let stageItems = [];

    const {
      whatIsThisSequenceAbout,
      overview,
      victorianCurriculumConnection,
      engagingParentsAndCarers,
      teachingStrategies,
      priorKnowledge,
      vocabulary,
      assessment,
    } = this.props.data.pageData;

    let stages = this.props.data.pageData.stages;

    if (whatIsThisSequenceAbout.length > 0)
      menuItems.push({
        item: 'What is this sequence about?',
        data: whatIsThisSequenceAbout,
      });
    if (this.props.data.pageData.stages.length > 0)
      menuItems.push({ item: 'Overview of stages', data: overview });
    if (priorKnowledge !== '')
      menuItems.push({ item: 'Prior knowledge', data: priorKnowledge });
    if (teachingStrategies !== '')
      menuItems.push({ item: 'Teaching strategies', data: teachingStrategies });
    if (vocabulary !== '')
      menuItems.push({ item: 'Vocabulary', data: vocabulary });
    if (assessment !== '')
      menuItems.push({ item: 'Assessment', data: assessment });
    if (victorianCurriculumConnection !== null)
      menuItems.push({
        item: 'Victorian Curriculum connections',
        data: victorianCurriculumConnection,
      });
    // if (engagingParentsAndCarers !== '') menuItems.push({item:'Engaging Parents and Carers', data: engagingParentsAndCarers})

    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];
      stageItems.push({ item: stage.stageTitle, data: stage, stage: true });
    }

    this.setState({
      menuItems: menuItems,
      stageItems: stageItems,
    });
  };

  getCheckedItems = () => {
    return new Promise((resolve) => {
      let checkedItems = document.querySelectorAll(
        '.mdc-checkbox__native-control[aria-checked="true"]'
      );
      let checkedItemNames = [];
      let allItems = [...this.state.menuItems, ...this.state.stageItems];

      for (let i = 0; i < checkedItems.length; i++) {
        const itemID = checkedItems[i].id;
        const labelText = document.querySelector(`label[for=${itemID}]`)
          .textContent;
        checkedItemNames.push(labelText);
      }

      let filteredItems = allItems.filter((item) =>
        checkedItemNames.includes(item.item)
      );

      this.setState({ checkedItems: filteredItems }, () => resolve());
    });
  };

  render() {
    let data = this.props.data;

    return (
      <div
        className={`sequence-sidenav__checklist checklist ${
          this.props.active ? 'active' : ''
        }`}
      >
        <button className='checklist-close' onClick={this.props.closeChecklist}>
          <CloseButton />
        </button>
        <p className='checklist__title'>Download and customise</p>
        <p className='checklist__subtitle'>
          Select sections of the learning sequence to export into either a pdf,
          Google Doc or word document.
        </p>
        <p className='checklist__sequence-title'>{data.pageData.title}</p>
        <form>
          {this.state.menuItems.map((item, key) => {
            return (
              <div className={`checklist__label-box`} key={`sequence-${key}`}>
                <Checkbox nativeControlId={`sequence-${key}`} checked />
                <label className='checklist__label' htmlFor={`sequence-${key}`}>
                  {item.item}
                </label>
                <br />
              </div>
            );
          })}
          {this.state.stageItems.length > 0 ? (
            <p className='checklist__stages-title'>Stages</p>
          ) : (
            ''
          )}
          {this.state.stageItems.map((item, key) => {
            return (
              <div className={`checklist__label-box`} key={`stage-${key}`}>
                <Checkbox nativeControlId={`stage-${key}`} checked />
                <label className='checklist__label' htmlFor={`stage-${key}`}>
                  {item.item}
                </label>
                <br />
              </div>
            );
          })}
        </form>
        <DownloadBox
          data={this.props.data}
          mobile={this.props.mobile}
          getCheckedItems={this.getCheckedItems}
          checkedItems={this.state.checkedItems}
        />
      </div>
    );
  }
}
