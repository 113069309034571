// @ts-ignore
import StaticpageTopLeft from '../../svgs/staticpage-top-left.svg'
// @ts-ignore
import StaticpageTopRight from '../../svgs/staticpage-top-right.svg'
// @ts-ignore
import StaticpageTopLeftAnimated from '../../svgs/staticpage-top-left-animated.svg'
// @ts-ignore
import StaticpageTopRightAnimated from '../../svgs/staticpage-top-right-animated.svg'
import React from 'react'

interface props {
  data: any
  browser: string
}

interface state {
}

export default class sequenceRibbons extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    
    return (
      <div className={`sequence__ribbons`}>
        {/* {this.props.browser == 'ie' || this.props.browser == 'edge' ? */}
        {true ?
          <>
            <StaticpageTopLeft className="sequence__left-svg"/>
            <StaticpageTopRight className="sequence__right-svg"/>
          </>
        :
          <>
            <StaticpageTopLeftAnimated className="sequence__left-svg"/>
            <StaticpageTopRightAnimated className="sequence__right-svg"/>
          </>
        }
      </div>
    )
  }
}

