import React from 'react';

import { parseHTML } from './../../helpers/parseHTML';

// @ts-ignore
import ArrowBlackDown from '../../svgs/arrow-black-down.svg';

interface props {
  data: any;
}

interface state {}

export default class StageContent extends React.Component<props, state> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {};

  triggerAccordion = (index: number) => {
    let accordionTextEl = document.getElementById(`section-${index}`);
    let accordionIconEl = document.getElementsByClassName(
      `stage-content__icon-${index}`
    )[0];
    let elHeight = accordionTextEl.scrollHeight + 30;
    let accordianButtonEl = document.getElementById(`section-${index}-button`);
    if (accordionTextEl.classList.contains('active')) {
      accordionTextEl.style.maxHeight = 0 + 'px';
      accordionTextEl.classList.remove('active');
      accordionIconEl.classList.remove('active');
      accordianButtonEl.setAttribute('aria-expanded', 'false');
    } else {
      accordionTextEl.style.maxHeight = elHeight + 'px';
      accordionTextEl.classList.add('active');
      accordionIconEl.classList.add('active');
      accordianButtonEl.setAttribute('aria-expanded', 'true');
    }
  };

  expandAll = () => {
    let contentSectionsLength = this.props.data.stageData.contentSections
      .length;

    for (let i = 0; i < contentSectionsLength; i++) {
      let accordionTextEl = document.getElementById(`section-${i}`);
      let accordionIconEl = document.getElementsByClassName(
        `stage-content__icon-${i}`
      )[0];
      let accordianButtonEl = document.getElementById(`section-${i}-button`);
      let elHeight = accordionTextEl.scrollHeight + 30;
      if (!accordionTextEl.classList.contains('active')) {
        accordionTextEl.style.maxHeight = elHeight + 'px';
        accordionTextEl.classList.add('active');
        accordionIconEl.classList.add('active');
        accordianButtonEl.setAttribute('aria-expanded', 'true');
      }
    }
  };

  render() {
    let data = this.props.data;
    let pageData = data.pageData;
    let stageData = data.stageData;

    return (
      <div className={`stage-content`}>
        <button className='stage-content__expand' onClick={this.expandAll}>
          Expand all
        </button>
        {stageData.contentSections.map((section, index) => {
          return (
            <div
              className='stage-content__section'
              id={`stage-section-${index}`}
              key={`stage-section-${index}`}
            >
              <button
                className='stage-content__section-title'
                onClick={() => this.triggerAccordion(index)}
                id={`section-${index}-button`}
                aria-controls={`section-${index}`}
                aria-expanded='false'
              >
                {section.sectionTitle}
                <ArrowBlackDown className={`stage-content__icon-${index}`} />
              </button>
              <div
                className='stage-content__section-text'
                id={`section-${index}`}
                role='region'
                aria-labelledby={`section-${index}-button`}
              >
                {parseHTML(section.sectionContent)}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
