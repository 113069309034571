import React from 'react'
import AnimatedLink from './../global/animatedLink';

import { parseHTML } from './../../helpers/parseHTML';
// @ts-ignore
import ArrowRightBlue from '../../svgs/arrow-right-blue.svg'

interface props {
  data: any
  stage: boolean
  mobile: boolean
}

interface state {
}

export default class StageList extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    let pageData = data.pageData;
    let filteredStages = pageData.stages.filter(stage => stage.stageTitle !== data.stageData.stageTitle);
    
    return (
      <div className={`stage-list`} id={`stage-links`}>
        <p className="stage-list__title">Other stages</p>
        {filteredStages.map((stage, index) => {
          return (
            <div className="stage-card" id={`stage-card-${index}`} key={`stage-card-${index}`}>
              <div className="stage-card__top">
                <p className="stage-card__title">{stage.stageTitle}</p>
                <AnimatedLink
                  to={`/${pageData.slug}/stages/${stage.stageRelativeUrl}`}
                  className={`stage-card__link`}
                >
                  EXPLORE <ArrowRightBlue/>
                </AnimatedLink>
              </div>
              <div className="stage-card__bottom">
                <div className="stage-card__bottom-left">
                  <p className="stage-card__subtitle">Suggested Learning Intentions</p>
                  {parseHTML(stage.suggestedLearningIntensions)}
                </div>
                <div className="stage-card__bottom-right">
                  <p className="stage-card__subtitle">Sample Success Criteria</p>
                  {parseHTML(stage.successCriteria)}
                </div>
                <AnimatedLink
                  to={`/${pageData.slug}/stages/${stage.stageRelativeUrl}`}
                  className={`stage-card__link bottom`}
                >
                  EXPLORE <ArrowRightBlue/>
                </AnimatedLink>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
