import React from 'react'
import Layout from '../layouts/layout';
import SEO from '../layouts/seo';
import { graphql } from 'gatsby'
import { connect } from 'react-redux';
import SequenceRibbons from '../sequence/sequence-ribbons';
import StageHeader from './../stage/stage-header';
import StageOutcomes from './../stage/stage-outcomes';
import StageCurriculum from './../stage/stage-curriculum';
import StageList from './../stage/stage-list';
import StageLastUpdated from './../stage/stage-lastupdated';
import StageContent from './../stage/stage-content';
import ReadingProgress from 'react-reading-progress-plus'
import StageLinks from './../stage/stage-links';
import Sidenav from './../global/sidenav';

interface props {
  data: any
  browser: string
  transitionStatus: string
  pageContext: any
}

interface state {
  tabletOrMobile: boolean
  renderNav: boolean
  progress: boolean
}

class Staticpage extends React.Component<props, state> {
  resizeEventListener: Function

  constructor(props) {
    super(props);
    this.state = {
      tabletOrMobile: false,
      renderNav: false,
      progress: false
    };
  }

  componentDidMount = () => {
    this.windowResizeEvent();
    setTimeout(() => {this.startProgressBar()}, 500);
  }

  startProgressBar = () => this.setState({progress: true});

  windowResizeEvent = () => {
    this.checkTabletOrMobile();
    let ticking = false;

    // Resize event with throttling
    window.addEventListener('resize', this.resizeEventListener = (e) => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          this.checkTabletOrMobile();
          ticking = false;
        });
        ticking = true;
      }
    }); 
  }

  checkTabletOrMobile = () => {
    if (window.innerWidth < 995) {
      this.setState({
        tabletOrMobile: true,
        renderNav: true
      })
    } else {
      this.setState({
        tabletOrMobile: false,
        renderNav: true
      })
    }
  }

  componentWillUnmount = () => {
    // @ts-ignore
    window.removeEventListener('resize', this.resizeEventListener);
  }

  render () {
    let data = this.props.data;
    let stageData = this.props.data.stageData;
    let {transitionStatus} = this.props;
    let context = this.props.pageContext;

    let parentsArray = [
      {url:'learning-areas', title:'Learning areas'},
      {url: data.pageData.slug, title: data.pageData.title},
      {url: data.pageData.slug + '/stages', title: 'Stages'}
    ]

    return (
      <>
        {this.state.progress ?
          <ReadingProgress targetEl="#sequence-content"/>
        :''}
        <SEO
          title={context.stageTitle} 
          description={data.pageData.seoDescription} 
        />
        <Layout
          data={data}
          class={data.pageData.slug}
          pageTitle={context.stageTitle} 
          parents={parentsArray}
          transitionStatus={transitionStatus}
        >
          <SequenceRibbons
            data={data}
            browser={this.props.browser}
          />
          <div className={`sequence-layout middle-container`}> 
            <div className="sequence-layout__left">
              {!this.state.tabletOrMobile && this.state.renderNav ? 
                <Sidenav
                  data={data}
                  mobile={false}
                  pageType={`stage`}
                />
              :''}
            </div>
            <div className="sequence-layout__right" id="sequence-content">
              <StageHeader
                data={data}
                title={context.stageTitle}
              />
              <StageOutcomes
                data={data}
                stageData={stageData}
                tabletOrMobile={this.state.tabletOrMobile}
              />
              {this.state.tabletOrMobile && this.state.renderNav ? 
                <Sidenav
                  data={data}
                  mobile={true}
                  pageType={`stage`}
                />
              :''}
              {data.stageData.stageCurriculumDescription !== null ? 
                <StageCurriculum
                  data={data}
                  mobile={this.state.tabletOrMobile}
                />
              :''}
              {data.stageData.contentSections.length > 0 ? 
                <StageContent
                  data={data}
                />
              :''}
              {/* {data.pageData.teachingStrategies !== '' ||
              data.pageData.engagingParentsAndCarers !== '' ||
              data.pageData.priorKnowledge !== '' ?
                <StageLinks
                  data={data}
                />
              :''} */}
              {data.pageData.stages.length > 1 ? 
                <StageList
                  data={data}
                  mobile={this.state.tabletOrMobile}
                  stage={context.stage}
                />
              :''}
              <StageLastUpdated
                data={data}
                stage={context.stage}
              />
            </div>
          </div>
        </Layout>
      </>
    )
  }
}


export const query = graphql`
  query($slug: String!, $stageId: String!) {
    site: datoCmsSite {
      ...siteData
    }
    nav: datoCmsNavigation {
      ...navData
    }
    footer: datoCmsFooter {
      ...footerData
    }
    pageData: datoCmsSequence(slug: {eq: $slug}) {
      slug
      title
      seoDescription
      lastUpdatedText
      levels
      primaryLevel
      subjects
      searchTags
      sequenceListingDotPoints
      whatIsThisSequenceAbout
      overview
      assessment
      teachingStrategies
      priorKnowledge
      vocabulary
      meta {
        updatedAt
      }
      victorianCurriculumConnection {
        title
        learningProgressionsText
        levels {
          ... on DatoCmsLevel {
            levelTitle
            levelContent
          }
        }
      } 
      stages {
        stageTitle
        stageRelativeUrl
        suggestedLearningIntensions
        lastUpdatedText
        successCriteria
        meta {
          updatedAt
        }
        contentSections {
          sectionContent
          sectionTitle
        }
        stageCurriculumDescription {
          contentDescriptionsContent {
            ... on DatoCmsContentDescriptionsText {
              contentText
            }
            ... on DatoCmsContentDescriptionsTitle {
              contentTitle
            }
          }
        }
      }
      sequenceImage {
        alt
        url
        fluid(maxHeight: 500) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    stageData: datoCmsSequenceStage(id: {eq: $stageId}) {
      stageTitle
      stageRelativeUrl
      suggestedLearningIntensions
      lastUpdatedText
      successCriteria
      meta {
        updatedAt
      }
      contentSections {
        sectionContent
        sectionTitle
      }
      stageCurriculumDescription {
        contentDescriptionsContent {
          ... on DatoCmsContentDescriptionsText {
            contentText
          }
          ... on DatoCmsContentDescriptionsTitle {
            contentTitle
          }
        }
      }
    }
  }
`

export default connect(
  state => ({ 
    browser: state.browser,
  })
)(Staticpage);