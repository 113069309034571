import React from 'react'
// @ts-ignore
import serviceWorker from 'workerize-loader!../../helpers/serviceWorker.js'
// @ts-ignore
import LoadingGif from '../../images/loading.gif'
// @ts-ignore
import DownloadIcon from '../../svgs/download-box-button.svg'
import { Helmet } from "react-helmet";
import moment from 'moment-es6'
import saveAs from 'file-saver';

// Service worker init
const DocumentServiceWorker = typeof window === 'object' && new serviceWorker()

interface props {
  data: any
  mobile: boolean
  getCheckedItems: Function
  checkedItems: any[]
}

interface state {
  loading: boolean
  loaded: boolean
  stdLoaded: boolean
  docxLoaded: boolean
  error: boolean
  url: string
  docxStatus: string
  driveStatus: string
  docxURL: string
  pdfBlob: string
  name: string
  errorMsg: string
}

export default class DownloadBox extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loaded: false,
      stdLoaded: false,
      docxLoaded: false,
      docxStatus: 'not requested',
      driveStatus: 'not requested',
      docxURL: '',
      pdfBlob: '',
      error: false,
      url: '',
      name: '',
      errorMsg: 'Please select an item above',
    };

  }

  componentDidMount = () => {
  }

  makeName = () => {
    const title = this.props.data.pageData.title.replace(/ +/g, '-').toLowerCase();
    const date = moment().format('DD-MM-YYYY'); 
    const filename = `${title}-${date}`;
    return filename
  }

  setError = (msg) => {
    this.setState({errorMsg: msg}, () => this.setState({error: true}))
    setTimeout(() => this.setState({error: false}), 3000);
    setTimeout(() => this.setState({errorMsg: ''}), 3100);
  }

  handlePDF = async () => {
    if (this.state.loaded) {
      saveAs(this.state.pdfBlob, `${this.makeName()}.pdf`);
    } else {
      await this.props.getCheckedItems();
      if (this.props.checkedItems.length == 0) {
        this.setError('Please select an item above')
        return
      } 

      this.setState({loading: true})
      
      try {
        let blob = await DocumentServiceWorker.buildPDF(this.props.checkedItems, this.props.data, window.location.origin);
        this.setState({loading: false, pdfBlob: blob}, () => {
          setTimeout(() => this.setState({loaded: true}), 100);
        })
      } catch (error) {
        console.log(error);
        this.setState({loading: false})
        this.setError('Something went wrong, please try again later')
      }
    }
  }

  saveToDrive = async () => {
    let PDFurl = await DocumentServiceWorker.s3upload(this.state.pdfBlob, this.makeName());
    
    // @ts-ignore
    gapi.savetodrive.render('std', {
      src: '//' + PDFurl.replace(/^https?\:\/\//i, ""),
      filename: this.makeName() + '.pdf',
      sitename: 'DET Learning Sequences'
    });

    let saveToDriveEl = document.getElementById('std');

    var observer = new MutationObserver((mutations) => {
      if (this.state.loaded) {
        this.setState({stdLoaded: true})
      }
    });

    var config = { attributes: true, childList: true, characterData: true };
    // pass in the target node, as well as the observer options
    observer.observe(saveToDriveEl, config);
  }

  startAgain = () => {
    this.setState({
      loaded: false,
      stdLoaded: false,
      docxLoaded: false,
      docxStatus: 'not requested',
      driveStatus: 'not requested',
    })
  }

  convertDocx = async () => {
    switch (this.state.docxStatus) {
      case 'not requested':
        this.setState({docxStatus: 'loading'})
        let data = await DocumentServiceWorker.cloudConvert(this.state.url);
        this.setState({docxStatus: 'loaded', docxURL: data.url})

        try {
          this.setState({docxStatus: 'loading'})
          let data = await DocumentServiceWorker.cloudConvert(this.state.url);
          this.setState({docxStatus: 'loaded', docxURL: data.url})
        } catch (error) {
          console.log(error);
          this.setState({docxStatus: 'not requested'});
          this.setError('Something went wrong, please try again later');
        }
        break;
      case 'loaded':
        window.open(this.state.docxURL)
        break;
    }
  }

  handleDocx = async () => {
    switch (this.state.docxStatus) {
      case 'not requested':
        this.setState({docxStatus: 'loading'})
        let data = await DocumentServiceWorker.buildDOCX(this.props.checkedItems, this.props.data, window.location.origin);
        this.setState({docxStatus: 'loaded', docxURL: data})
        break;
      case 'loaded':
        saveAs(this.state.docxURL, `${this.makeName()}.docx`);
        break;
    }
  }

  handleDrive = async () => {
    switch (this.state.driveStatus) {
      case 'not requested':
        this.setState({driveStatus: 'loading'})
        await this.saveToDrive();
      break;
    }
  }

  helmet = () => {
    return (
      // @ts-ignore
      <Helmet>
        <script src="https://apis.google.com/js/platform.js" async defer></script>
      </Helmet>
    )
  }

  render () {
    let data = this.props.data;
    
    return (
      <>
        {this.helmet()}
        <div className="download-box">
          <p className="download-box__text">
            {this.props.mobile ? 'Note: PDF downloads may be several MB in size, please use WIFI when creating a PDF. ' : ''}
            PDF creation may take a few seconds, PDF creation is much faster on modern browsers.
            Use <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> or <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Firefox</a> for the best results.
          </p>
          <button
            onClick={this.handlePDF}
            className={`
              download-box__download-button ${this.state.loaded ? '' : 'active'} 
              ${this.state.loading ? 'loading' : ''}
              ${this.state.error ? 'loading' : ''}
            `}
          >
            Download and customise
          </button>
          <p className={`download-box__loading error ${this.state.error ? 'active' : ''}`}> 
            {this.state.errorMsg}
          </p>
          <p className={`download-box__loading ${this.state.loading ? 'active' : ''}`}>
            <img src={LoadingGif} alt=""/> LOADING
          </p>
          <button
            onClick={this.handlePDF}
            className={`download-box__download-button ${this.state.loaded ? 'active' : ''}`}
          >
            <DownloadIcon/> Download PDF
          </button>
          <button
            onClick={this.startAgain}
            className={`download-box__white-button ${this.state.loaded ? 'active' : ''}`}
          >
            Start again
          </button>
          <button 
            className={`download-box__white-button ${this.state.loaded ? 'active' : ''}`}
            onClick={this.handleDocx}
            disabled={this.state.docxStatus === 'loading' || this.state.docxStatus === 'error'}
          >
            {this.state.docxStatus === 'not requested' ? 
              'Convert to DOCX'
            : this.state.docxStatus === 'loading' ?
              <> <img src={LoadingGif} alt="" className="loading"/> Loading </>
            : this.state.docxStatus === 'loaded' ?
              <> <DownloadIcon/> Download DOCX </>
            : this.state.docxStatus === 'error' ?
              "An error has occurred"
            : null}
          </button>
          <button 
            className={`download-box__white-button ${!this.state.stdLoaded && this.state.loaded ? 'active' : ''}`}
            onClick={this.handleDrive}
            disabled={this.state.driveStatus === 'loading' || this.state.driveStatus === 'error'}
          >
            {this.state.driveStatus === 'not requested' ? 
              'Upload for Google Drive'
            : this.state.driveStatus === 'loading' ?
              <> <img src={LoadingGif} alt="" className="loading"/> Uploading </>
            : this.state.driveStatus === 'error' ?
              "An error has occurred"
            : null}
          </button>
          <div className={`save-to-drive ${this.state.stdLoaded ? 'active' : ''}`}>
            <div id="std" className={this.state.stdLoaded ? 'active' : ''}></div>
            <div className={`save-to-drive__inner`}>
              <DownloadIcon/> Save to Google Drive
            </div>
          </div>
        </div>
      </>        
    )
  }
}
