import React from 'react'

import { parseHTML } from './../../helpers/parseHTML';
import AnimatedLink from './../global/animatedLink';
import animateScrollTo from 'animated-scroll-to';

interface props {
  data: any
  mobile: boolean
}

interface state {
  contentActive: boolean
  achievementActive: boolean
  learningActive: boolean
  contentOffset: number
  achievementOffset: number
  learningOffset: number
}

export default class StageCurriculum extends React.Component<props, state> {

  constructor(props) {
    super(props);
    this.state = {
      contentActive: true,
      achievementActive: false,
      learningActive: false,
      contentOffset: 0,
      achievementOffset: 0,
      learningOffset: 0,
    };
  }

  
  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    let cdata = data.stageData.stageCurriculumDescription;
    
    return (
      <div className={`stage-curriculum`} id={`curriculum`}>
        <p className="stage-curriculum__title">Content Descriptions</p>
        <div className="stage-curriculum__content">
          <div className={`stage-curriculum__content-inner content-descriptions ${this.state.contentActive ? 'active' : ''}`}>
            {cdata.contentDescriptionsContent.map((content, index) => {
              if (content.hasOwnProperty('contentTitle')) {
                return (
                  <p className="content-descriptions__title" key={`content-${index}`}>
                    {content.contentTitle}
                  </p>
                )
              } else {
                return (
                  <div className="content-descriptions__row" key={`content-${index}`}>
                    <div className="content-descriptions__row-left stage">
                      {parseHTML(content.contentText)}
                    </div>
                  </div>
                )
              }
            })}
          </div>
      </div>
      </div>
    )
  }
}