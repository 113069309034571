import React from 'react'
import { parseHTML } from './../../helpers/parseHTML';

// @ts-ignore
import Triangle from '../../svgs/stage-triangle.svg'
// @ts-ignore
import MobileTriangle from '../../svgs/stage-mobile-triangle.svg'

interface props {
  data: any
  stageData: any
  tabletOrMobile: boolean
}

interface state {
}

export default class StageOutcomes extends React.Component<props, state> {
  
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentDidMount = () => {
  }

  render () {
    let data = this.props.data;
    let stageData = data.stageData;
    
    return (
      <div className={`stage-outcomes`} id={`outcomes`}>
        <div className="stage-outcomes__content">
          <div className="stage-outcomes__left">
            {this.props.tabletOrMobile ? 
              <MobileTriangle/>
            :
              <Triangle/>
            }
            <p className="stage-outcomes__subtitle">Suggested Learning Intentions</p>
            {parseHTML(stageData.suggestedLearningIntensions)}
          </div>
          <div className="stage-outcomes__right">
            <p className="stage-outcomes__subtitle">Sample Success Criteria</p>
            {parseHTML(stageData.successCriteria)}
          </div>
        </div>
      </div>
    )
  }
}
